










































import {Component} from 'vue-property-decorator'
import VcSwitch from '@/components/VCSwitch.vue'
import {settingsStore} from '@/store/modules/settings'
import {mixins} from 'vue-class-component'
import Notifications from '@/components/mixins/Notifications'
import ToolTip from "@/components/custom/ToolTip.vue"

@Component({
  name: 'contacts',
  components: {VcSwitch, ToolTip}
})
export default class Contacts extends mixins(Notifications) {
  hint: string = 'Shared contacts are contacts (customers) that<br>have been shared with you by another Pigeon user'
  get autoSharing() {
    return !!settingsStore.isAutoSharing
  }

  set autoSharing(value: boolean) {
    settingsStore.changeAutoSharing(value)
  }
}
